import React, {useState} from "react";
import {Link} from "gatsby";
import styled from "styled-components";

const Menu = styled.div`
  width: 300px;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  border-right: 2px solid black;
  z-index: 10;
  transition: all .2s ease-in-out;
  transform: ${({nav}) => (nav ? "translateX(0)" : "translateX(-100%)")};
`

const TopNav = styled.div`
    transition: all .2s ease-in-out;
    transform: ${({nav}) => (nav ? "translateX(300px)" : "translateX(0)")};
`

const NavBar = ({changeNavState}) => {

    const [nav, showNav] = useState(false);


    return (
        <nav>
            <TopNav className="nav__container" nav={nav}>
                <div className="nav__left">
                    <div className="nav__left__menu">
                        <p className="small bold">Smarte Energie <br/> für Ihr Zuhause</p>
                        <div onClick={() => {showNav(!nav);changeNavState(!nav)}} className="btn outline black menu__btn smalltext bold">menü</div>
                    </div>
                    <div className="list__container">
                        <Link className="list__element smalltext" to={"/service/photovoltaik"}><span className="listnr">01</span> Photovoltaik</Link>
                        <Link className="list__element smalltext" to={"/service/batteriespeicher"}><span className="listnr">02</span> Batteriespeicher</Link>
                        <Link className="list__element smalltext" to={"/service/waerme"}><span className="listnr">03</span> Wärme</Link>
                        <Link className="list__element smalltext" to={"/service/e-mobilitaet"}><span className="listnr">04</span> E-Mobilität</Link>
                    </div>
                </div>
                <div className="nav__right">
                    <div className="nav__logo__container">
                        <img src={`../eniosol_logo_desktop_black.svg`} alt="Eniosol Logo"/>
                    </div>
                </div>
            </TopNav>
            <Menu nav={nav}>
                <div className="menu__close" onClick={() => {showNav(!nav);changeNavState(!nav)}}>
                    <div></div>
                    <div></div>
                </div>
                <img src={`../eniosol_logo_desktop_black.svg`} alt="Eniosol Logo" className="menu__logo"/>
                <div className="menu__links">
                    <Link className="menu__link smalltext uppercase bold" to={"/home"}>Home</Link>
                    <Link className="menu__link smalltext uppercase bold" to={"/ueber-eniosol"}>Über Eniosol</Link>
                    <Link className="menu__link smalltext uppercase bold" to={"/service"}>Service</Link>
                    <Link className="menu__link smalltext uppercase bold" to={"/green-blog"}>Green Blog</Link>
                    <Link className="menu__link smalltext uppercase bold" to={"/karriere"}>Karriere</Link>
                    <Link className="menu__link smalltext uppercase bold" to={"/meldung"}>Störungsmeldung</Link>
                    <Link className="menu__link smalltext uppercase bold" to={"/kontakt"}>Kontakt</Link>
                </div>
                <div className="menu__social__container">
                    <div className="social__link">f</div>
                    <div className="social__link">i</div>
                    <div className="social__link">in</div>
                </div>
                <div className="menu__newsletter__container">
                    <p className="smalltext uppercase bold">Newsletter <br/> abonnieren</p>
                    <input className="inputfield smalltext" type="text"/>
                    <div className="btn filled black newsletter__btn"><p className="smalltext uppercase bold">Abonnieren</p></div>
                    <p className="smalltext newsletter__dsgvo">Wir verarbeiten Ihre Daten gemäß <Link to={"/dsgvo"}>Datenschutzbestimmungen</Link></p>
                </div>
            </Menu>
        </nav>
    )
}


export default NavBar
