import React from "react";
import "../styles/global.scss";
import styled from "styled-components"
import {Link} from "gatsby";

const JoblistContainer = styled.div`
  
  border-top: 2px solid black;
  &:last-of-type {
  border-bottom: 2px solid var(--black-color);
}
`

const JobList = ({slug, title, area, time}) => {
    return (
        <JoblistContainer className="joblist__container">
            <div className="joblist__inner">
                <h4 class={"uppercase"}>{title}</h4>
                <div className="joblist__infocontainer">
                    <div className="joblist__info">
                        <p className="large">Raum: <br></br> {area}</p>
                        <p className="large">{time} Std/ <br></br> Woche</p>
                    </div>
                    <Link to={slug} className="btn filled small green smalltext bold jobbtn hover-moveup">MEHR ERFAHREN</Link>
                </div>
            </div>
        </JoblistContainer>
    )
}
export default JobList
