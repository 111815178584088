import React, {useState} from "react";
import Navbar from "../components/Navbar";
import JobList from "../components/JobList";
import {graphql} from "gatsby";

import Marquee from "react-fast-marquee";
import Layout from "../components/Layout";
import Head from "../components/Head";

export const query = graphql`
    {
    allSanityJob{
    edges
    {
    node{
      title
      raum
      stunden
      slug{
        current
      }
      status
    }
  }
  }
  allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  }
}
`


const Karriere = ({data}) => {

    return (
        <Layout settings={data.allSanitySettings.edges[0].node}>
            <Head title="Karriere"/>
                <Marquee gradient={false} speed={40} className={'mobilemargin'}>
                    <div className="marqueeText color-orange">
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                        <span className="marqueeText__inner">JOBS</span>
                    </div>
                </Marquee>
            {data.allSanityJob.edges.map(({node: job}) => {
                if(job.status === 'open'){
                    return <JobList area={job.raum} time={job.stunden} title={job.title} slug={job.slug.current}></JobList>
                }
            })}
        </Layout>
    )
}

export default Karriere;
